@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&family=Nunito:wght@300;400;500;600;700;800;900&display=swap');

html {
	width: 100%;
}
body {
	margin: 0;
	padding: 0;
	font-family: 'Inter', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.bg-primary {
	background-color: #f3eddd;
}
.bg-secondary {
	background-color: #fcfbf7;
}

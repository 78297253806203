.blackout {
	position: absolute;
	width: 100%;
	height: 70%;
	left: 0;
	bottom: 0;
	background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
	border-radius: 24px;
}
.banner {
	margin-top: 32px;
	height: 606px;
	background: url(./bg.jpg);
	background-position: center center;
	background-size: cover;
	border-radius: 24px;
	padding: 0 46px;
	position: relative;
}

.header {
	display: flex;
	justify-content: space-between;

	color: #fff;

	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	padding-top: 24px;
}

.wrapper {
	position: absolute;
	bottom: 50px;
}

.title {
	color: #fff;
	font-family: Inter;
	font-size: 56px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 16px;
}

.subtitle {
	color: #fff;
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 28px;
}

.features__box {
	display: flex;
	align-items: center;
}

.items__wrapper {
	display: flex;
	align-items: center;
	margin-left: 28px;
	padding-right: 28px;
	border-right: 2px solid #979797;
}

.items__wrapper:first-child {
	margin-left: 0;
}

.items__wrapper:last-child {
	margin-right: 0;
	border: none;
}

.feature__item {
	display: inline-flex;
	flex-direction: column;
	padding: 0 24px 0 8px;
	flex-shrink: 0;
}

.feature__title {
	color: #fff;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0;
}

.feature__subtitle {
	margin: 0;

	color: #fff;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

@media screen and (max-width: 1440px) {
	.features__box {
		display: grid;
		grid-template-columns: 222px 225px 314px 355px;

		grid-column-gap: 23px;
		grid-row-gap: 22px;
	}

	.feature__item {
		margin-bottom: 10px;
	}

	.items__wrapper {
		border: none;
		margin-left: 0;
	}
}
@media screen and (max-width: 1350px) {
	.features__box {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);

		grid-column-gap: 23px;
		grid-row-gap: 10px;
	}
}
@media screen and (max-width: 1025px) {
	.features__box {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(4, 1fr);

		grid-column-gap: 23px;
		grid-row-gap: 10px;
	}

	.title {
		color: #fff;
		font-family: Inter;
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	.subtitle {
		color: #fff;
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	.banner {
		margin-top: 0;
		border-radius: 0 0 24px 24px;
	}
}

@media screen and (max-width: 650px) {
	.banner {
		margin-top: 0;
		border-radius: 0 0 24px 24px;
		padding: 0 16px;
	}

	.feature__title {
		font-size: 16px;
	}
	.feature__subtitle {
		font-size: 16px;
		max-width: 250px;
	}

	.title {
		font-size: 24px;
	}

	.subtitle {
		font-size: 20px;
	}
}

.wrapper {
	width: 100%;
	background-color: #f3eddd;
}

.container {
	max-width: 1440px;
	margin: auto;
	padding: 37px 56px 194px;
}

@media screen and (max-width: 1400px) {
	.container {
		padding: 37px 56px 479px;
	}
}

@media screen and (max-width: 1025px) {
	.container {
		padding: 0 0 479px;
	}
}

.wrapper {
	width: 100%;
	background-color: #fcfbf7;
}

.container {
	max-width: 1440px;
	margin: auto;
	padding: 428px 56px 0px;
	position: relative;
}

.title {
	margin: 0;
	margin-bottom: 32px;
	color: #404040;
	font-family: Inter;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.item__wrapper {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
}

.item {
	max-width: 416px;
	display: flex;
	padding: 32px;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	margin: 0 8px;

	align-self: stretch;
	border-radius: 24px;
	border: 1px solid #bfbfbf;
	background: #fff;
	backdrop-filter: blur(76px);
}

.item__title {
	margin: 0;
	color: #404040;
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.list__wrapper {
	height: 75px;
	margin: 0;
	color: #404040;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}

.price {
	margin: 0;
	color: #404040;
	font-family: Inter;
	font-size: 34px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	align-self: stretch;
}

.btn {
	display: flex;
	padding: 16px 64px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border: none;
	border-radius: 12px;
	background: #fe7536;
	cursor: pointer;
	text-decoration: none;

	color: #fff;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
}

.position {
	margin-top: -550px;
	margin-bottom: 71px;
}

@media screen and (max-width: 1400px) {
	.item__wrapper {
		flex-wrap: wrap;
		justify-content: center;
		gap: 25px;
	}

	.position {
		margin-top: -850px;
		margin-bottom: 71px;
	}
}

@media screen and (max-width: 1025px) {
	.title {
		color: var(--Gray-900, #404040);
		font-family: Inter;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.item {
		display: flex;
		width: 343px;
		padding: 32px;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
	}

	.item__title {
		width: 214px;

		color: #404040;

		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.price {
		color: #404040;
		font-family: Inter;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.btn {
		display: flex;
		padding: 16px 64px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		align-self: stretch;
		border-radius: 12px;
		background: #fe7536;
	}
}

@media screen and (max-width: 500px) {
	.container {
		padding: 428px 15px 86px;
	}
}

.photo {
	height: 400px;
}
.blackout {
	background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
	border-radius: 12px;
	height: 200px;
	width: 300px;
	bottom: 0;
	position: absolute;
}
.slider__title {
	color: #404040;
	font-family: Inter;
	font-size: 36px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 33px;
}

.icon__block {
	bottom: 29px;
	position: absolute;
	z-index: 9;
}

.block__img {
	margin-bottom: 7px;
	width: 52px;
	height: 52px;
	flex-shrink: 0;
}

.icon__block {
	width: 225px;
	color: #fff;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.block__title {
	padding: 0 24px;
	width: 225px;
	color: #fff;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

@media screen and (max-width: 1025px) {
	.slider__title {
		color: #404040;

		font-family: Inter;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
}

@media screen and (max-width: 857px) {
	/* .container {
		padding: 428px 15px 86px;
	} */

	.slider__title {
		color: #404040;

		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
}

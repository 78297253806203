.swiper-button-prev,
.swiper-button-next {
	display: flex;
	width: 52px;
	height: 52px;

	border-radius: 36px;
	border: 2px solid #404040;
	justify-content: center;

	background: transparent;
	position: relative;
	top: 71px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
	font-size: 30px;
	color: #404040;
	cursor: pointer;
}

.swiper {
	padding-bottom: 86px;
}

.swiper-button-next {
	left: 80px;
	top: 37px;
}

@media screen and (min-width: 320px) {
	.swiper-slide > img {
		width: 290px;
		border-radius: 12px;
	}
}
@media screen and (min-width: 350px) {
	.swiper-slide > img {
		width: 300px;
	}
}
@media screen and (max-width: 400px) {
	.swiper-slide {
		display: flex;
		justify-content: center;
	}
}

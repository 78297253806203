.modal {
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal__content {
	display: flex;
	max-width: 521px;
	padding: 32px;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	border-radius: 24px;
	border: 1px solid #bfbfbf;

	background: #fff;
}
.modal__content > input {
	display: flex;
	padding: 16px 24px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border-radius: 12px;
	border: 1px solid #bfbfbf;

	background: #fff;
	color: #808080;
	font-size: 20px;
}
.modal__content > input::placeholder {
	color: #808080;

	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 160% */
}

.title {
	margin: 0;
	align-self: stretch;
	color: #404040;

	text-align: center;
	font-family: Inter;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.subtitle {
	margin: 0;
	align-self: stretch;
	color: #404040;

	text-align: center;
	padding: 0 40px;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.btn {
	display: flex;
	padding: 16px 64px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	border: none;
	border-radius: 12px;
	background: #fe7536;
	cursor: pointer;

	color: #fff;
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 133.333% */
}

a {
	text-decoration-color: black;
}
.wrapper {
	width: 100%;
	background-color: #fcfbf7;
}

.container {
	max-width: 1440px;
	margin: auto;
	padding: 71px 56px 53px;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.title {
	margin: 0;
	color: #404040;
	font-family: Inter;
	font-size: 36px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-bottom: 15px;
}

.block {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 286px;
	color: #404040;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 160% */
	margin-bottom: 15px;
}

.link__box {
	display: flex;
	gap: 24px;
}

.link {
	color: #404040;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 32px; /* 160% */
	text-decoration: none;
}

.container > iframe {
	margin: 0 0 50px 25px;
}

@media screen and (max-width: 1360px) {
	.block {
		font-size: 16px;
	}
}

@media screen and (max-width: 947px) {
	.block {
		font-size: 16px;
	}

	.container {
		flex-direction: column-reverse;
		padding: 31px 25px 53px;
		align-items: stretch;
	}

	.container > iframe {
		margin: 0 0 50px 0;
	}
}
@media screen and (max-width: 375px) {
	.block {
		font-size: 16px;
	}

	.link {
		font-size: 16px;
	}
}

.header {
	display: flex;
	justify-content: space-between;
}
.link__wrapper {
	display: flex;
	gap: 32px;
	margin-left: -250px;
}

.link {
	color: #404040;
	font-family: Inter;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	text-decoration: none;
	transition: 0.3s;
}

.link:hover {
	color: #fe7536;
}
